import { Link } from "@StarberryUtils"
import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import GoogleReview from "../../images/google-reviews-color.png"
import "./Contact.scss"
import ReactMarkdown from "react-markdown"
import PlayVideo from "@Components/Play/PlayVideo"
import GetURL from "@Components/common/site/get-url"
import getVideoId from "get-video-id"
import YouTube from "@u-wave/react-youtube"
import { useStaticQuery, graphql } from "gatsby"
import { uknumberformatFront } from "@Components/common/common-functions"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import axios from "axios"
import wordsToNumbers from "words-to-numbers"
import { Helmet } from "react-helmet"
import GoogleMap from "./GoogleMap"
const Contact = props => {
  const [testimonials, setTestimonials] = useState([])
  const [isClient, setClient] = useState(false)

  const strapiconfig = {
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }

  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig) // could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
    getitems(url)
    setClient(true)
  }, [])
  var testimonial_count = testimonials ? testimonials.length : 0
  var rating_count = 0
  testimonials &&
    testimonials.length > 0 &&
    testimonials.map((item, ind) => {
      rating_count += parseInt(wordsToNumbers(item.starRating))
    })
  var rating_avg = rating_count / testimonial_count

  const latestReviewsScore = useStaticQuery(graphql`
    query {
      allStrapiGoogleReviewReviews {
        edges {
          node {
            id
            starRating
          }
        }
        pageInfo {
          totalCount
        }
      }
    }
  `)
  const reviews = latestReviewsScore.allStrapiGoogleReviewReviews.edges
  const reviewsCount =
    latestReviewsScore.allStrapiGoogleReviewReviews.pageInfo.totalCount
  const wordToNumber = { ONE: 1, TWO: 2, THREE: 3, FOUR: 4, FIVE: 5 }
  let sumOfScores = 0

  reviews.map(review => {
    const reviewStringValue = review.node.starRating
    sumOfScores += wordToNumber[reviewStringValue]
    return sumOfScores
  })

  const averageRating = Math.round((sumOfScores / reviewsCount) * 10) / 10
  const [isPlay, setPlay] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const playVideo = video_id => {
    setShowVideo(true)
  }
  const trackerVideo = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Video Play Btn",
      formType: event,
      formId: "Video Play",
      formName: "Video",
      formLabel: "Video",
    })
  }
  if (props?.contactData?.Embed_Video_URL) {
    var videoid = getVideoId(props?.contactData?.Embed_Video_URL)
  }
  let processedImages = JSON.stringify({})
  if (props.contactData?.imagetransforms?.Image_Transforms) {
    processedImages = props.contactData?.imagetransforms.Image_Transforms
  }
  return (
    <div className="contact-page">
      <Container>
        <Helmet>
          <script type="application/ld+json">{`{
                    "@context": "http://schema.org",
                    "@type": "Product",
                    "name" : "Estate Agents in Manchester",
                    "image": "https://www.bentleyhurst.co.uk/images/mail-logo.png",
                    "url": "https://www.bentleyhurst.co.uk/contact-manchester-estate-agents/contact/",
                    "description" : "The Manchester estate agents are happy to help you find fantastic properties in the area. Just call us, it's the first step to your dream home",
                    "brand" : {
                        "@type" : "Brand",
                        "name" : "Bentley Hurst - Manchester Estate Agents",
                        "image" : "https://www.bentleyhurst.co.uk/images/mail-logo.png"
                    },
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "4.9",
                        "bestRating": "5",
                        "ratingCount": "276"
                    } 
                }`}</script>
        </Helmet>
        <Row>
          <Col lg="7">
            <div className="d-lg-block padding-right">
              <h1 className="contact-h1">Contact Bentley Hurst</h1>
              <a href={"tel:" + props.contactData?.Phone} className="phone">
                {props.contactData?.Phone}
              </a>
              <Link to={"contact"} className="email">
                {props.contactData?.Email}
              </Link>
              <Link to={"contact"} className="btn btn-primary margin-right">
                Contact Us
              </Link>
              {props.contactData?.CTA?.CTA_1_Label &&
                props.contactData?.CTA?.CTA_1_URL?.Alias && (
                  <GetURL
                    label={props.contactData?.CTA?.CTA_1_Label}
                    alias={props.contactData?.CTA?.CTA_1_URL?.Alias}
                    class={"btn btn-primary"}
                  />
                )}

              <div className="google-review-block">
                <div className="">
                  <img
                    className="disable-max-width"
                    src={GoogleReview}
                    alt="Google Reviews Bentley Hurst"
                  />
                </div>
                <p>
                  Rated{" "}
                  <strong>
                    {rating_avg ? parseFloat(rating_avg.toFixed(1)) : ""}/5
                  </strong>{" "}
                  from {testimonial_count ? testimonial_count : ""} Customer
                  Reviews.
                </p>
              </div>

              <table>
                <tr>
                  <td>
                    <b>Address:</b>
                  </td>
                  <td>
                    <div className="rich-text-editer-block">
                      <a href="https://maps.app.goo.gl/KAJExtmm4cmBRUrX7">
                        <ReactMarkdown
                          source={props.contactData?.Address}
                          allowDangerousHtml
                        />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Opening Hours:</b>
                  </td>
                  <td>
                    <div className="rich-text-editer-block">
                      <ReactMarkdown
                        source={props.contactData?.Opening_Hours}
                        allowDangerousHtml
                      />
                    </div>
                  </td>
                </tr>
              </table>

              <GoogleMap />
              <div className="review-cta-section">
                <a
                  class="btn btn-primary margin-right"
                  href="https://g.page/r/CXQrmt98espaEBM/review"
                  target="_blank"
                >
                  Leave a Review
                </a>
              </div>
              <div className="contact-persion-blk">
                {props.contactData.speak_with.map((node, index) => {
                  return (
                    <div className="negotiator d-flex align-items-center">
                      <figure>
                        <img
                          src={node?.Image?.url}
                          alt={node?.Image?.alternativeText}
                        />
                      </figure>
                      <div className="info">
                        <strong className="name">{node?.Name}</strong>
                        <p className="post">{node?.Designation}</p>
                        <a
                          className="contact-no"
                          href={"tel:" + uknumberformatFront(node?.Phone)}
                        >
                          {uknumberformatFront(node?.Phone)}
                        </a>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="contact-person-desc">
                <p>
                  <a href="/about-us/reviews">See all reviews</a>
                </p>
                <h2>
                  Expert Estate Agent Coverage in Manchester's Property Market{" "}
                </h2>
                <p>
                  <a href="/">Bentley Hurst Estate Agents</a>, a dynamic and
                  innovative choice for Manchester property services. Our
                  expertise extends across the{" "}
                  <a href="/about-us/areas-we-cover/">
                    diverse neighborhoods of this vibrant city
                  </a>
                  . From residential to commercial properties, we are committed
                  to providing exceptional service as leading estate agents in
                  Manchester. Trust us to navigate the local market and assist
                  you in achieving your real estate goals. Contact Bentley Hurst
                  today for a personalized and professional experience.
                </p>
                <p>
                  Browse all our{" "}
                  <a href="/property/for-sale/in-manchester/">
                    properties for sale in Manchester
                  </a>{" "}
                  or{" "}
                  <a href="/property/to-rent/in-manchester/">
                    properties for rent in Manchester
                  </a>
                </p>
              </div>
            </div>
          </Col>

          <Col lg="5" className="pr-0 fixed-right d-lg-block">
            <ImageTransform
              imagesources={props.contactData?.Image?.url}
              renderer="bgImg"
              imagename="contact.Image.bgimg"
              attr={{ alt: "", className: "contact-bg" }}
              imagetransformresult={processedImages}
              id={props.contactData?.id}
            >
              {showVideo && (
                <YouTube
                  video={videoid.id}
                  autoplay
                  onEnd={e => {
                    setShowVideo(false)
                  }}
                  modestBranding={1}
                  onPlaying={trackerVideo("contact page video")}
                  showRelatedVideos={false}
                  showInfo={false}
                  annotations={false}
                />
              )}
              {showVideo ? null : props?.contactData?.Embed_Video_URL ? (
                <strong
                  className="play-btn"
                  onClick={e => {
                    playVideo(videoid.id)
                  }}
                >
                  <i className="icon-play"></i>
                </strong>
              ) : null}
            </ImageTransform>
          </Col>
        </Row>
      </Container>
      <PlayVideo
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoId={props?.contactData?.Embed_Video_URL}
        isAutoPlay={1}
      />
    </div>
  )
}

export default Contact
